
#login-box{
    margin-top: 150px;
}
.login-page{
    width: 100%;
    max-width: 500px;
    border-radius: 6px;
    background-color: #fff;
    margin: 0 auto;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 6px #ccc;
}
.login-page h1{
    letter-spacing: 1px;
    font-weight: 500;
    margin: 0 0 24px;
}
.login-field{
    width: 100%;
    padding-top: 16px;
    font-size: 14px;
    box-sizing: border-box;
    border: 0;
    border-bottom: 2px solid #8c8c8c;
    margin-bottom: 16px;
    outline: none;
}
.login-btn{
    display: inline-block;
    border-radius: 30px;
    padding: 14px 36px;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    background-color: #20b883;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 24px;
}

.filter-option input{
    border-radius: 24px;
}