.product-section{
    margin-top: 70px;
    padding: 60px;
    display: flex;
    gap: 40px;
}



.left-part{
    width: 15%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
}



.label-part{
    display: flex;
    /* margin-top: 20px; */
    justify-content: center;
    align-items: left;
    gap: 5px;
    
}

.right-part{
    width: 75%;
    margin-top: 100px;
}



.search-part1{
    padding-left: 20px;
    gap: 15px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.search-part h3,p{
    padding-left: 100px;
}


table{
    border-collapse: collapse;
}

tbody tr{
    border-bottom: 5px solid #f6f7fb;
    height: 80px;
}

.light-font{
    color: #8c8c8c
}
.dark-font{
    color: rgba(0,0,0,.8);
    font-weight: 500;
}




